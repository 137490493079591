<template lang="pug">
.k-index-container-kolcenter-embed-box
  b-loading(
    :active="loading"
    :is-full-page="false"
    @update:active="loadingHandler"
  )
  .header-wrapper(v-if="$slots.header")
    slot(name="header")
      .title
      .center
      .action

  .banner-wrapper(v-if="$slots.banner")
    slot(name="banner")

  .content-wrapper
    slot(name="body")
</template>

<script setup>
import { onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  loading: { type: Boolean }
})

const loadingHandler = (loading) => {
  emit('update:loading', loading)
}
</script>

<style scoped lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'

.k-index-container-kolcenter-embed-box
  position: relative
  > .header-wrapper
    +utils.is-flex(vc)
    justify-content: space-between
    margin-bottom: size.$gap * .75
    font-weight: font.$semibold
    color: color.$merlin
    > .title
      margin: 0
      font-size: font.$size-6
      font-weight: font.$bold

    > .title > .icon
      margin-right: .5rem
      +utils.until(size.$ipad)
        margin-left: .5rem

    > .action
      +utils.is-flex
      justify-content: flex-end
      gap: 1rem
      font-size: font.$size-7
      a
        color: color.$merlin
  .banner-wrapper
    margin-bottom: 2rem
  // .content-wrapper
</style>
