import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_media_accounts',
  attributes: [
    'id',
    'promoter_member_id',
    'media_service_id',
    'username',
    'link_url',
    'fans_count',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['media_service_id', 'username', 'link_url', 'fans_count']
}

export default class PromoterMediaAccount extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  AdminFetchAndSaveIgData() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/admin_fetch_and_save_ig_data`
    )
  }
}
