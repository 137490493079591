<template lang="pug">
.k-index-container-admin-content-box.box.content-box(:class="containerClass")
  b-loading(
    :active="loading"
    :is-full-page="false"
    @update:active="loadingHandler"
  )
  .top-wrapper.mb-2(v-if="$slots.top")
    slot(name="top")
  .box-header.with-border(v-if="$slots.header")
    slot(name="header")
  .box-body
    .search-wrapper(v-if="$slots.search")
      slot(name="search")
    .tool-wrapper.box-tools(v-if="$slots.tool")
      .tools
        slot(name="tool")
    .filter-wrapper(v-if="$slots.filter")
      slot(name="filter")
    .help-message
      slot(name="message")
    .list
      slot(name="body")
      slot
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const HEADER_TYPE_MAP = {
  'is-primary': 'is-primary',
  'is-info': 'is-info',
  'is-success': 'is-success',
  'is-warning': 'is-warning',
  'is-danger': 'is-danger'
}

const props = defineProps({
  headerType: { type: String, default: () => 'is-primary' },
  noBox: { type: Boolean, default: () => false },
  noContentBox: { type: Boolean, default: () => false },
  loading: { type: Boolean },
  options: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const emit = defineEmits(['update:loading'])

const loadingHandler = (loading) => {
  emit('update:loading', loading)
}

const headerTypeClass = computed(() => {
  return HEADER_TYPE_MAP[props.options.headerType] || 'is-primary'
})

const containerClass = computed(() => {
  return [headerTypeClass.value].filter(Boolean).join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'

.k-index-container-admin-content-box
  overflow: hidden
  position: relative
  +utils.until(size.$ipad)
    width: 100vw
    margin: 0
    padding: .5rem
  > .header
    margin-bottom: size.$gap * 1.25
    display: flex
    flex-direction: column
    gap: size.$gap
    +utils.from(size.$ipad)
      flex-direction: row
      justify-content: space-between
      align-items: center
      > .title, > .center, > .action
        width: 33.3%

    > .title
      font-size: font.$size-5
      font-weight: font.$bold

    > .title > .icon
      margin-right: .5rem
      +utils.until(size.$ipad)
        margin-left: .5rem

    > .action
      display: flex
      justify-content: flex-end
      gap: 1rem
      font-size: font.$size-6

  .search-wrapper
    margin-bottom: size.$gap

  .filter-wrapper
    margin-bottom: 0
</style>
