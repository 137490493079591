<template lang="pug">
.k-index-container-landing-kol-box
  b-loading(
    :active="loading"
    :is-full-page="false"
    @update:active="loadingHandler"
  )
  .header-wrapper(v-if="$slots.header")
    slot(name="header")
      .title
      .center
      .action

  .banner-wrapper(v-if="$slots.banner")
    slot(name="banner")

  .content-wrapper
    slot(name="body")

  .footer-wrapper
    slot(name="footer")
</template>

<script setup>
import { onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  loading: { type: Boolean }
})

const loadingHandler = (loading) => {
  emit('update:loading', loading)
}
</script>

<style scoped lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'

.k-index-container-landing-kol-box
  min-height: 500px
  position: relative
  .banner-wrapper
    margin-bottom: -70px
  .content-wrapper
    z-index: 2
    max-width: 1380px
    padding: 0 1rem

    //- odd-container
    position: relative
    margin: 0 auto
    width: 100%

  .footer-wrapper
    display: flex
    justify-content: center
    align-items: center
    height: 185px

    .button
      width: 240px
      height: 40px
</style>
