<template lang="pug">
.k-index-container(
  :is="component"
  :loading="loading"
  :title="title"
  :subtitle="subtitle"
  :options="options"
  @update:loading="loadingChangeHandler"
)
  template(
    v-if="$slots.top"
    #top
  )
    slot(name="top")

  template(
    v-if="$slots.header"
    #header
  )
    slot(name="header")

  template(
    v-if="$slots.banner"
    #banner
  )
    slot(name="banner")

  template(
    v-if="$slots.stats"
    #stats
  )
    slot(name="stats")

  template(
    v-if="$slots.search"
    #search
  )
    slot(name="search")

  template(
    v-if="$slots.tool"
    #tool
  )
    slot(name="tool")

  template(
    v-if="$slots.filter"
    #filter
  )
    slot(name="filter")

  template(
    v-if="$slots.message"
    #message
  )
    slot(name="message")

  template(#body)
    slot(name="body")

  template(
    v-if="$slots.footer"
    #footer
  )
    slot(name="footer")
</template>

<script setup>
import { ref, computed } from 'vue'
import AdminContentBox from './k_index_container/admin-content-box.vue'
import AdminEmbed from './k_index_container/admin-embed.vue'
import AdminModalBox from './k_index_container/admin-modal-box.vue'
import KolcenterContentBox from './k_index_container/kolcenter-content-box.vue'
import KolcenterEmbedBox from './k_index_container/kolcenter-embed-box.vue'
import LandingKolBox from './k_index_container/landing-kol-box.vue'

const STYLE_TYPE_MAP = {
  'admin-content-box': AdminContentBox,
  'admin-embed': AdminEmbed,
  'admin-modal-box': AdminModalBox,
  'kolcenter-content-box': KolcenterContentBox,
  'kolcenter-embed-box': KolcenterEmbedBox,
  'landing-kol-box': LandingKolBox
}

const props = defineProps({
  styleType: { type: String },
  title: { type: String },
  subtitle: { type: String },
  loading: { type: Boolean },
  options: { type: Object }
})

const emit = defineEmits(['update:loading'])

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})

const loadingChangeHandler = (value) => {
  emit('update:loading', value)
}
</script>
