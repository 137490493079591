<template lang="pug">
.k-index-container-kolcenter-content-box
  b-loading(
    :active="loading"
    :is-full-page="false"
    @update:active="loadingHandler"
  )
  .header(v-if="$slots.header")
    slot(name="header")
    //- header example
      .title
      .center
      .action
  .stats-wrapper
    slot(name="stats")
  .search-wrapper
    slot(name="search")
  .filter-wrapper(v-if="$slots.filter")
    slot(name="filter")
  .content-wrapper
    slot(name="body")
</template>

<script setup>
import { onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  loading: { type: Boolean }
})

const loadingHandler = (loading) => {
  emit('update:loading', loading)
}
</script>

<style scoped lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'

.k-index-container-kolcenter-content-box
  position: relative
  min-height: 500px
  overflow: hidden
  margin-inline: -(size.$od-main-content-padding-inline)
  padding-inline: size.$od-main-content-padding-inline
  +utils.until(size.$ipad)
    width: 100vw
    margin: 0
    padding: .5rem
  > .header
    margin-bottom: size.$gap * 1.25
    +utils.is-flex
    flex-direction: column
    gap: size.$gap
    +utils.from(size.$ipad)
      flex-direction: row
      justify-content: space-between
      align-items: center
      > .title, > .center, > .action
        width: 33.3%

    > .title
      font-size: font.$size-5
      font-weight: font.$bold

    > .title > .icon
      margin-right: .5rem
      +utils.until(size.$ipad)
        margin-left: .5rem

    > .action
      +utils.is-flex
      justify-content: flex-end
      gap: 1rem
      font-size: font.$size-6
      a
        color: color.$black

  .filter-wrapper
    margin-bottom: size.$gap * 0.5
</style>
